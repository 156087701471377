import React, { useState, useRef } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  content: {
    fontSize: 12,
  },
});

const MyDocument = ({ content }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.content}>{content}</Text>
      </View>
    </Page>
  </Document>
);

const PDF = () => {
  const [content, setContent] = useState('This is the content for the PDF file.');
  const contentRef = useRef(null);

  const handleGeneratePdf = () => {
    // Update the state with the content from the ref
    if (contentRef.current) {
      setContent(contentRef.current.innerText);
    }
  };

  return (
    <div>
      <h1>Generate PDF from Ref</h1>
      <div ref={contentRef}>
        <p>This content will be used in the PDF. You can modify it.</p>
      </div>
      <button onClick={handleGeneratePdf}>Generate PDF</button>

      <PDFDownloadLink
        document={<MyDocument content={content} />}
        fileName="generated.pdf"
      >
        {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
      </PDFDownloadLink>
    </div>
  );
};

export default PDF;
